import { mapGetters, mapActions } from "vuex"
import { getHeadersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"

export default {
  name: "Automations",
  data: () => ({
    highlightAutomation    : false,
    onDraggingAutomation   : false,
    dragEnterAutomation    : false,
    draggingAutomation     : false,
    automationsAreDraggable: false,
    helpCenterUrl          : process.env.VUE_APP_HELP_CENTER_URL
  }),
  methods: {
    ...mapActions({
      updateAutomation: "automations/updateAutomation"
    }),
    getClassForAutomationRow(item) {
      let classForAutomationRow = ""
      if (item.id === this.dragEnterAutomation?.id &&
        item.id !== this.draggingAutomation?.id) {
        if (this.dragEnterAutomation.sortingOrder <
          this.draggingAutomation.sortingOrder) {
          classForAutomationRow += "drop-row-down"
        } else {
          classForAutomationRow += "drop-row-up"
        }
      } else if (this.highlightAutomation) {
        classForAutomationRow += "grey lighten-4"
      } else {
        classForAutomationRow += "white"
      }
      return classForAutomationRow
    },
    onAutomationDragStart(automation) {
      this.draggingAutomation  = automation
      this.dragEnterAutomation = this.draggingAutomation
    },
    onAutomationDragEnter(automation) {
      this.dragEnterAutomation = automation
    },
    onAutomationDragOver(event) {
      event.preventDefault()
    },
    onAutomationDragEnd() {
      if (this.draggingAutomation.id !== this.dragEnterAutomation.id) {
        const isAutomationDraggedUp =
          !!(this.draggingAutomation.sortingOrder - this.dragEnterAutomation.sortingOrder)

        const sortingOrder = isAutomationDraggedUp ?
          this.dragEnterAutomation.sortingOrder : this.dragEnterAutomation.sortingOrder - 1
        this.updateAutomation({
          id: this.draggingAutomation.id,
          sortingOrder
        })
      }
    },
    handleClickOnRow(item) {
      this.$router.push({
        name  : "automation-edit",
        params: {
          id: item.id
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      automations           : "automations/automations",
      isAutomationAdded     : "automations/isAutomationAdded",
      isLoadingAutomations  : "automations/isLoadingAutomations",
      isSortingOrderUpdated : "automations/isSortingOrderUpdated",
      isUpdatingSortingOrder: "automations/isUpdatingSortingOrder"
    }),
    automationInfoToDisplay() {
      return [{
        icon    : "mdi-alphabetical",
        title   : this.$t("1092"),
        subTitle: this.$t("1093")
      }, {
        icon    : "mdi-gesture-tap",
        title   : this.$t("1094"),
        subTitle: this.$t("1095")
      }, {
        icon    : "mdi-lightning-bolt-outline",
        title   : this.$t("1096"),
        subTitle: this.$t("1097")
      }, {
        icon    : "mdi-shape-outline",
        title   : this.$t("1098"),
        subTitle: this.$t("1099")
      }, {
        icon    : "mdi-robot-industrial",
        title   : this.$t("1197"),
        subTitle: this.$t("1198")
      }]
    },
    isAutomationsTableDataLoading() {
      return this.isLoadingAutomations ||
        this.isUpdatingSortingOrder
    },
    automationsToDisplay() {
      return [...this.automations].sort((firstAutomation, secondAutomation) =>
        firstAutomation.sortingOrder - secondAutomation.sortingOrder)
    },
    headersForAutomationsTable() {
      return getHeadersForTable(TABLE_NAMES.AUTOMATIONS, this.$t.bind(this))
    }
  },
  watch: {
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.draggingAutomation  = undefined
          this.dragEnterAutomation = undefined
          this.highlightAutomation = false
        }
      }
    }
  }
}
